/* ==========================================================================
	Thank You message
========================================================================== */

.thankyouMessage{
	position: relative;

	h2{
		color:#000;
		@include adjust-font-size-to(36px);
		font-weight:300;
	}

	.printWarning{
		border:1px solid rgba($gray,0.3);
		border-radius:3px;
		padding: 30px 15px;
		background:$lightgray;
		margin-bottom:1.5em;

		h3{
			font-weight:600;
			margin:0;
		}
	}

	.print_receipt{
		background:$form-blue;
		text-transform:uppercase;
		text-shadow: 0 1px 0 #333;

		&:hover{
			background:lighten($form-blue, 15);
		}

		&:after{
			content:'\e103';
			font-family:'ElegantIcons';
			color:#fff;
			text-shadow: 0 1px 0 #333;
			right:10px;
		}
	}

	.submitAnother{
		background:$form-blue;
		text-transform:uppercase;
		text-shadow: 0 1px 0 #333;
		width:254px;

		&:hover{
			background:lighten($form-blue, 15);
		}
	}
}

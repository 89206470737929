/* ==========================================================================
	Home
========================================================================== */

.home{
	
	.pageWrapper{
		background:url('../images/stethoscope.jpg') no-repeat left top;
		min-height:855px;
		padding:40px;
		max-width:800px;
		margin:0 auto;
	
	}

	#header{
		display: none;
	}

	#footer{
		display: none;
	}
}


.welcome{
	text-align:center;
	margin: 72px auto 50px;
	width: 468px;
	position: relative;

	
	h2 {
		margin: 0;
		@include adjust-font-size-to(48px);
		text-transform:uppercase;
	}

	p {
		margin: 0;
		color:#658ea7;
		text-transform:uppercase;
		@include adjust-font-size-to(18px);
	}

}

p.forms-modal {
	margin: 0 0 20px;
	text-align: center;
	button {
		display: inline-block;
		padding: 0;
		text-decoration: none;
		text-transform: uppercase;
		color: $blue;
		font-weight: bold;
		line-height: 16px;
		border: 0;
		border-bottom: 1px solid $tab-shadow;
		background-color: transparent;
		transition: color 0.2s ease;
		&:hover {
			color: lighten($link-color,30);
			border-color: transparent;
		}
	}
}

.peer-intro-text {
	margin-top: 40px;
	margin-bottom: 0;
}

.peer-intro-text-link {
	display: block;
	margin-top: 20px;
	text-transform: uppercase;
	color: $blue;
	text-transform: uppercase;
	font-weight: bold;
}

.getStarted{
	width:468px;
	margin:0 auto;
	position: relative;
	text-align:center;
}

div#form-list {
	padding: 40px 60px 30px;
	border-radius: 5px;
	h3 {
		margin: 0 0 30px;
		text-transform: uppercase;
		text-align: center;
		color: $darkblue;
		font-size: 18px;
		line-height: 18px;
	}
}

ul.form-list {
	column-count: 2;
	list-style: none;
	margin: 0 0 30px;
	padding: 0;
	h4 {
		margin: 0;
	}
	ul {
		list-style-type: disc;
		margin: 0;
		padding: 0 0 0 30px;
	}
	li {
		overflow: hidden; /* Fix for firefox and IE 10-11  */
		-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
		page-break-inside: avoid; /* Firefox */
		break-inside: avoid; /* IE 10+ */
		break-inside: avoid-column;
		font-size: 14px;
		line-height: 20px;
	}
}

div.form-list__close {
	text-align: center;
}

/* ==========================================================================
	Buttons
========================================================================== */

.button{
	padding:8px 49px 8px 17px;
	display: inline-block;
	text-align: left;
	background:$blue;
	color:#fff;
	text-transform:uppercase;
	@include adjust-font-size-to(14px);
	@include font-smoothing;
	text-decoration:none;
	position: relative;
	border-radius:2px;
	border: 0;
	box-shadow:0 1px 0 #bcbdc0;
	transition: all 0.2s ease;

	&:after{
		content:'\35';
		font-family:'ElegantIcons';
		color:inherit;
		font-size:18px;
		position: absolute;
		right:5px;
		top:7px;
		text-shadow:0 1px 0 #333;

	}

	&:hover{
		color:#fff;
		background:lighten($blue, 10);
	}
}
/* ==========================================================================
	Layout
========================================================================== */

.pageWrapper{
	position: relative;
	overflow:hidden;
}

#content{
	position: relative;
	margin:0 auto;
	padding:0;
	width:768px;
}

#formcontainer{
	position: relative;
	margin:0 auto;
	padding:0;
	width:768px;
}

.tabs{
	position: relative;
	padding:0;
	margin:0;
	@include clearfix;
	

	li{
		background:$tab-blue;
		display: inline-block;
		vertical-align:top;
		float:left;
		width:33.3%;
		padding:30px 25px 10px;
		color:#fff;
		text-transform:uppercase;
		color:#fff;
		@include adjust-font-size-to(12px);
		font-weight:600;
		@include font-smoothing;
		border:1px solid #2D5870;
		border-bottom:0;
		border-left:0;
		position: relative;

		&.active{
			color:#000;
			background:#fff;
			border-top:0;
			padding-top:31px;

			span{
				color:$form-blue;
			}

			&:before{
				content:'';
				top: -20px;
				left: -2px;
				right: -1px;
				position: absolute;
				height:20px;
				background:#fff;
				border-top:1px solid $form-blue;
				border-right:1px solid $form-blue;
				border-left:1px solid $form-blue;
			}
		}

		&.next{
			border-left:6px solid $tab-shadow;
			background:$tab-blue;
		}

		&.prev{
			border-right:6px solid $tab-shadow;
			background:$tab-blue;
			left:-1px;
		}
	}

	#tabone{
		z-index: 10;
	}

	#tabtwo.active{
		&:before{
			border-left:1px solid $form-blue;
		}
	}
	#tabthree.active{
		border-right:0;

		&:before{
			border-left:1px solid $form-blue;
		}
	}

	span{
		display: block;
		@include adjust-font-size-to(32px);
		line-height:20px;
	}
}

.internal{
	#content{
		width:100%;
	}

	.breadcrumbs{
		background:$darkblue;
		padding:10px;

		ul{
			max-width:768px;
			margin:0 auto;
			padding:0;
			list-style-type: none;
		}

		li{
			display: inline-block;
			margin-right:10px;
			vertical-align:top;
			position: relative;

			&:first-of-type{
				a{
					&:after{
						content:'\5e';
						font-family:'ElegantIcons';
						color:#fff;
						font-size:8px;
						position: absolute;
						right:-11px;
						top:1px;
					}
				}
			}

			&.active{
				a{
					text-decoration:underline;
				}
			}
		}

		a{
			color:#fff;
			text-decoration:none;
			@include adjust-font-size-to(14px);
			display: block;

			&:hover{
				color:$lightblue;
				text-decoration:underline;
			}
		}
	}

	.internalcontent{
		max-width:768px;
		margin:0 auto;
	}

}
/* ==========================================================================
	Typography
========================================================================== */

$base-font-size: 16px;
$base-line-height: 24px;
$round-to-nearest-half-line: true;

@include establish-baseline;

html {
	color: #222;
}
body { 
	font-family: $sans-serif;
	position: relative;
}

h1, .h1 {
	@include font-size(36px);
	margin: 0 auto rhythm(1,36px);
}
h2, .h2 {
	@include font-size(24px);
	margin: rhythm(1.5,24px) auto rhythm(0.5,24px);
	color:$darkblue;
}
h3, .h3 {
	@include font-size(18px);
	margin: rhythm(1.6,18px) auto rhythm(0.4,18px);
}
h4, .h4 {
	@include font-size(16px);
	margin: rhythm(1.75) auto rhythm(0.25);
}
p {
	margin: 0 auto rhythm(1);
}
a {
	color: $link-color;
	text-decoration: underline;
	transition: color 0.2s ease;

	&:hover {
		text-decoration: none;
		color: lighten($link-color,30);
	}
}
ul, ol {
	margin: 0 0 rhythm(1);

	ul, ol {
		margin: 0;
	}
}
blockquote {
	@include font-size(24px);
	font-family: $serif;
	padding: rhythm(2,24px);
	margin: 0 auto rhythm(1,24px);
}
table {
	width: 100%;
	margin: 0 auto rhythm(1);
}
tbody {
	border: none;
}
th {
	text-align: left;
	text-transform: uppercase;
	padding: 0 1em;
	border: none;
}
td {
	padding: 10px 1em 11px;
	border-bottom: 1px solid #fff;
}
img {
	margin-bottom: rhythm(1);
}
.intro {
	@include font-size(18px);
	@include background-image(logo);
}
/* ==========================================================================
	Form Elements
========================================================================== */

.form-wrapper{
	position: relative;
	padding:60px 10px;

	@include breakpoint($large){
		padding:60px 0;
	}

}

.formTitle{
	font-weight:300;
}

.instructions{
	border:1px solid rgba($gray,0.3);
	border-radius:3px;
	padding: 30px 15px;
	background:$lightgray;
	margin-bottom:1.5em;

	.h3{
		margin:0;
		font-weight:600;
	}

	p{
		margin:0;
	}

}

.formFields{
	padding-top:10px;

	.legend{
		@include adjust-font-size-to(18px);
		font-weight:600;
		color:$form-blue;
		border-bottom:1px solid $gray;
		padding-bottom:15px;

		&.required {
			span {	
				@include adjust-font-size-to(18px);
				color:$red;
			}
		}
		&.vertical-margin {
			margin: 30px 0;
		}
	}
}


.form-element-container{
	display: block;
	margin:0 auto rhythm(1);
}

.formrow{
	display: block;
	width:700px;
	@include clearfix;
	position: relative;

	table{
		display: inline-block;
		width: auto;

		.form-element-wrapper{
			display: block;
			margin:0;
		}

		label{
			float:none;
			width:auto;
			margin:0 0 0 15px;
		}
	}
}

.col1{
	display: inline-block;
	width:150px;
	vertical-align:top;
}

.col2{
	text-align:center;
	width:500px;
	display: inline-block;
	vertical-align:bottom;

	label{
		width:165px;
	}
}

label.checkbox-agreement {
	width:460px;
}

.firstRow{
	width:193px;
}

.visitCol2{
	clear:both;
	display: inline-block;
	vertical-align:top;
	margin-top:10px;
}

.visitInfo{
	@include clearfix;

	.checkboxWrapper{
		display: inline-block;
		vertical-align:top;
		padding-top:12px;
	}

	.formrow{
		display: inline-block;
		vertical-align:top;
		width:auto;

		label{
			font-size:0.75em;
		}

		input{
			width:50px;
			border:1px solid $form-blue;
			border-radius:3px;
			padding:5px;
		}
	}
}

.repeatableFieldsContainer{
	position: relative;

	.form-element-wrapper{
		display: block;
		margin:0;

		input, textarea{
			margin-bottom: 1.5em;
		}

		span.error{
			margin: 0 0 10px 160px;
		}
	}

	.dropdown{
		margin-bottom:1.5em;
	}

	.addedRow{
		position: relative;

		input, textarea{
			margin-bottom: 1.5em;
		}

		.formrow{
			width:auto;
		}
	}

	.extraFields{
		display: block;
		margin:0 0 20px;
		left:15px;
		background:$lighterblue;
		padding:10px 45px 10px 15px;
		color:$darkblue;
		font-weight:600;
		text-transform:uppercase;
		text-decoration:none;
		@include font-smoothing;
		position: relative;
		border:0;
		transition: background 0.2s ease;

		&:hover{
			background-color:$lightblue;
		}

		&:after{
			content:'';
			font-family:'ElegantIcons';
			font-size:20px;
			position: absolute;
			right:10px;
		}
	}

	.addMore{
		background:$lighterblue url('../images/icon-plus.png') no-repeat 93%;
		background-image:url('../images/icon-plus.svg'), none;
		left:155px;

		&:after{
			content:'\e050';
		}
	}

	.removeButton{
		float:right;
		display: inline-block;
		vertical-align: top;
		position: absolute;
		top:0; right:19%;
	}

	.remove{
		background:$lighterblue url('../images/icon-minus.png') no-repeat 93%;
		background-image:url('../images/icon-minus.svg'), none;
		width:156px;

		&:after{
			content:'\e04f';
		}
	}

	.active{
		display: block;
	}

	.repeatFields{
		.formrow{
			display: none;

			&:nth-of-type(1){
				display: block;
			}
		}
	}

	#upDrug{
		.removeButton{
			top:-7px;
		}
	}
}

.form-element-wrapper{
	display: inline-block;
	vertical-align: top;
	margin:0 auto rhythm(1);
	position: relative;
//	width:245px;
//	float:left;

	span.formError, span.error{
		color:$red !important;
		border-top:2px solid $red;
		margin-top: 10px;
		display: block;
		@include adjust-font-size-to(11px);

		&:before{
			content:'\e063';
			font-family:'ElegantIcons';
			margin-right:8px;

		}
	}
}

.supporting-text {
	display: block;
	width: 250px;
	@include adjust-font-size-to(14px);
}

label,
.label {
	font-weight:600;
	@include adjust-font-size-to(14px);
	line-height:14px;
	width:155px;
	padding-right:22px;
	text-align:right;
	display: inline-block;
	vertical-align:middle;
	margin: 10px 0 0;
	float:left;
	color: #000;

	&.required span{
		@include adjust-font-size-to(18px);
		color:$red;
	}

	sup{
		top:-0.25em;
	}
}

input.textbox, textarea{
	border-radius:3px;
	padding:10px;
	border:1px solid $form-blue;
	width:245px;
	display: inline-block;
	vertical-align:top;
}

.phonenumber{

	input.textbox{
		width:80px;
		margin-right:10px;
	}

	legend {
		font-weight:600;
		@include adjust-font-size-to(14px);
		line-height:14px;
		width:155px;
		padding-right:22px;
		text-align:right;
		display: inline-block;
		vertical-align:middle;
		margin-top:10px;
		float: left;
		&.required span{
			@include adjust-font-size-to(18px);
			color:$red;
		}
		sup{
			top:-0.25em;
		}
	}
}

input.textbox.short{
	width:80px;
}

input.textbox.long{
	width:510px;
}

.selectStyle{
	background:$form-blue;
	padding:0;
	position: relative;
	min-width:245px;
	border:1px solid $form-blue;
	overflow:hidden;
	display: inline-block;
	vertical-align:top;
	color:#FFF;
	@include font-smoothing;
	border-radius:3px;

	&:after{
		content:'\43';
		font-family:'ElegantIcons';
		position: absolute;
		top:0; right:0;
		padding:10px 12px;
		font-size:16px;
		background:$form-blue;
		line-height:25px;
		color:#FFF;
		border-left:1px solid #fff;
	}

	select{
		width: 105%;
		border: 0;
		padding: 10px 24px 11px 11px;
		background-color:transparent;
		background-image: none;
		-webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    position:relative;
	    z-index:1;
	    cursor: pointer;
	    color:#FFF;
	    @include font-smoothing;
	//    -moz-appearance: window;
	}

	select:disabled,
	select[disabled] {
		color: #FFF;
	}

	option {
		color: #000;
	}
}

select::-ms-expand{
	display: none;
}

:-moz-any(.selectStyle):after {
	background-color: $form-blue;
	pointer-events: none;
	z-index: 1;
}

#divDiagnosis{
	.selectStyle{
		min-width: 330px;
	}
}

input.hasDatepicker{
	background-image:url('../images/datepicker.jpg');
	background-repeat:no-repeat;
	background-position: 95%;
	background-size:20px;
}

li:not(#foo) > span input[type='checkbox'], div:not(#foo) > span input[type='checkbox']{
	opacity:0;
	float:left;
	width: 18px;
	left: 14px;
	position: relative;
	cursor:pointer;
}

li:not(#foo) > span input[type='checkbox'] + label, div:not(#foo) > span input[type='checkbox'] + label{
	margin:0;
	clear:none;
	padding: 0px 0 0px 24px;
	cursor:pointer;
	display: block;
	background:url('../images/checkbox-unchecked-white.png') no-repeat left 3px;
	background-image: url('../images/checkbox-unchecked-white.svg'), none;
	background-size: 17px;
	font-weight: 800;
	text-align:left;
}

li:not(#foo) > span input[type='checkbox']:checked + label, div:not(#foo) > span input[type='checkbox']:checked + label{
	background:url('../images/checkbox-checked-white.png') no-repeat left 3px;
	background-image: url('../images/checkbox-checked-white.svg'), none;
	background-size: 17px;
}

div:not(#foo) > input[type='checkbox']{
	opacity:0;
	float:left;
	width: 18px;
	left: 14px;
	position: relative;
	cursor:pointer;
}

div:not(#foo) > input[type='checkbox'] + label{
	margin:0;
	clear:none;
	padding: 0px 0 0px 24px;
	cursor:pointer;
	display: block;
	background:url('../images/checkbox-unchecked-blue.png') no-repeat left top;
	background-image: url('../images/checkbox-unchecked-blue.svg'), none;
	background-size: 14px;
	text-align:left;
}

div:not(#foo) > input[type='checkbox']:checked + label{
	background:url('../images/checkbox-checked-blue.png') no-repeat left top;
	background-image: url('../images/checkbox-checked-blue.svg'), none;
	background-size: 14px;
}

	.description{
		position: relative;
		left:155px;
		@include adjust-font-size-to(14px);
		margin-bottom:rhythm(1);
		width:590px;
	}

.dynamicCheckboxes{

	.formrow{
		width:auto;
	}

	.checkboxWrapper{
		padding-top:12px;

		label{
			text-indent: -9999px;
		}
	}
}

.clear{
	clear:both;
}

.bottom_controls{
	display: block;
	margin:0 auto;
	width:728px;

	span.error{
		color:$red !important;
		margin-bottom:25px;
		text-align: center;
	}
}

.formsubmit{
	background:$form-blue url('../images/submit-arrow.png') no-repeat 95%;
	background-image: url('../images/submit-arrow.svg'), none;
	background-size:10px;
	border:0;
	min-width: 182px;
  margin-left:19%;
  margin-bottom: 1rem;
  &:after {
    content: none;
  }

	&:hover{
		background-color:lighten($form-blue, 15);
		background-image: url('../images/submit-arrow.svg'), none;
		background-repeat:no-repeat;
		background-position: 95%;
		background-size:10px;
	}
}

.prev{
	background:$form-blue url('../images/submit-arrow-prev.png') no-repeat 5%;
	background-image: url('../images/submit-arrow-prev.svg'), none;
	background-size:10px;
	padding-left: 28px;

	&:hover{
		background-color:lighten($form-blue, 15);
		background-image: url('../images/submit-arrow-prev.svg'), none;
		background-repeat:no-repeat;
		background-position: 5%;
		background-size:10px;
	}
}


//hysterectomy form

#divWhyNoPelvicUterineSparing{
	.selectStyle{
		min-width:350px;
	}
}

#ctl00_ContentPlaceHolderMain_ddlPatientInfo_Timeframe {
	width: 240px;
	padding-right: 44px;
}
.clinical-disclaimer {
	margin: 0 auto;
	max-width: 768px;
	font-size: 0.875em;
}
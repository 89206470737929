/* ==========================================================================
	admin
========================================================================== */

#main_nav{
	margin:0 auto;
	padding:0;
	float:left;

	li{
		display: inline-block;
		float:left;

		a{
			padding:10px 10px 10px 0;
			font-size:0.875em;
			display: block;

			&:hover{
				color:lighten($blue, 15);
			}
		}
	}
}

#tertiary_nav{
	margin:0 auto;
	padding:0;
	float:right;

	li{
		display: inline-block;
		float:left;

		a{
			padding:10px 10px 10px 0;
			font-size:0.875em;
			display: block;

			&:hover{
				color:lighten($blue, 15);
			}
		}
	}
}

.adminContent{
	position: relative;
	min-height:300px;

	.even{
		background:$lightblue;
	}

	.formrow{
		margin-bottom:10px;
	}

	.formsubmit{
		margin-left:156px;
	}

	.download_forms{
		display: none;
		background:#fff;
		list-style-type: none;
		padding:0;

		li{
			padding:15px 20px;
			@include clearfix;
			border-bottom:1px solid $gray;
		}

		.formName{
			display: inline-block;
			vertical-align:top;
			float:left;
			margin-top:7px;
		}

		.downloadOptions{
			display: inline-block;
			vertical-align:top;
			float:right;
		}

		.downloadButton{
			margin:0 15px;
			width:auto;
		}
	}

	.searchCol1{
		position: relative;
		display: inline-block;
		width:40%;
		vertical-align:top;

		label{
			float:none;
			text-align:left;
		}
	}

	.searchCol2{
		position: relative;
		display: inline-block;
		width:50%;
		vertical-align:top;

		label{
			float:none;
			text-align:left;
		}

		.formsubmit{
			width: auto;
			margin: auto;
			float:right;
			vertical-align:top;
		}
	}

	.testForms{
		margin-bottom:15px;

		.button{
			margin: 0;
		}
	}
}

.download-forms {
	list-style: none;
	padding-left: 0;

	li {
		padding: 15px 20px;
		border: 1px solid $gray;
		@include clearfix;

		&.even {
			background: none;
		}

		.formName {
			display: inline-block;
			vertical-align: top;
			float: left;
			margin-top: 7px;
		} 

		.downloadOptions{
			display: inline-block;
			vertical-align: top;
			float: right;
		}	
	

		.formsubmit {
			color: #fff;
			text-align: left;
			text-transform: uppercase;
			font-size: .875em;
			margin: 0 15px;
			border-radius: 2px;
			width: auto;
			padding: 12px 49px 12px 17px;
		}
	}
}
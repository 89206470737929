/* ==========================================================================
	Service accordian
========================================================================== */


.serviceCategories{
	padding:0;
	list-style-type: none;

	> li{
		display: block;
		border:1px solid $lightblue;
		background:$lighterblue;
		margin:0 auto 4px;
		border-radius:3px;
		position: relative;

		p{
			font-weight:600;
			padding:20px;
			margin:0;
			@include font-smoothing;
			@include adjust-font-size-to(18px);
			color:$darkergray;
			cursor:pointer;

			&:after{
				content:'\4c';
				font-family:'ElegantIcons';
				color:$form-blue;
				font-size:30px;
				position: absolute;
				top:18px; right:5px;
			}
		}

		.plusOpen{
			&:after{
				content:'\4d';
				color:#fff;
				text-shadow:1px 1px 1px #000;
			}
		}
	}

	li.open{
		background:$darkblue;

		p{
			border-bottom:1px solid $lightblue;
			color:#fff;
		}
	}
}

.serviceTypes{
	display: none;
	list-style: none;
	padding:0;

	li{
		display: block;
		border-bottom:1px solid $lightblue;
		padding:20px;

		&.selected{
			background: $form-blue;
		}
	}

	label{
		float:none;
		width: auto;
		font-weight:800;
		@include adjust-font-size-to(14px);
		text-transform:uppercase;
		color:#fff;
		@include font-smoothing;
	}
}
/* ==========================================================================
	Header
========================================================================== */

#header{
	background: #E6EEF1;
	padding:40px;

	.wrapper{
		max-width:768px;
		margin:0 auto;
	}

	h1{
		@include adjust-font-size-to(24px);
		text-transform:uppercase;
		margin:0;
	}

	a{
		text-decoration:none;
		color:$darkblue;
	}
}



/* ==========================================================================
	Variables
========================================================================== */

// Fonts
$sans-serif:  "proxima-nova", Arial, Helvetica, sans-serif;
$serif:       "Times New Roman", serif;

// Colors
$darkblue:        #27648c;
$blue:			      #0067ab;
$tab-blue: 		    #4F7A92;
$form-blue:	      #537B97;
$tab-shadow:	    #3E6981;
$lightblue: 	    #d2dfe6;
$lighterblue: 	  #f2f6f8;

$gray:            #d9dadb;
$lightgray:       #f0f1f1;
$darkgray:	      #898b8e;
$darkergray:	    #464648;

$red:             #cd1e24; 

// Color Assignments
$link-color:  $blue;

// Media Query Breakpoints
$xsmall:      20em;  // 320px
$small:       30em;  // 480px
$medium:      48em;  // 768px
$large:       64em;  // 1024px
$xlarge:      80em;  // 1280px
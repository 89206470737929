/* ==========================================================================
	Tool Tips
========================================================================== */
a.icon{
	display: inline-block;
	font-size:0;
	text-decoration:none;

	&:before{
		content:'\74';
		font-family:'ElegantIcons';
		color:$form-blue;
		font-size:16px;
		top:9px;
		position: relative;
		left:0;

	}
}
.tooltipBox{
	display: inline-block;
	vertical-align:top;

}

.toolTip{
	display: none;
	width:280px;

	p{
		margin-bottom:0;
	}

	.close{
		font-size:0;
		text-decoration:none;
		line-height:15px;
		display: block;

		&:before{
		content:'\4d';
		font-family:'ElegantIcons';
		color:#000;
		font-size:20px;
		float:right;
		position: relative;
		}
	}
	
}

.activeToolTip{
	opacity:1;
	visibility: visible;
//	left:65%; top:-55px;
//	margin-top:-15px;
//	margin-left:-15px;
	width:280px;

	

	.container{
		position: relative;
		
	}
}


.qtip{
	position: absolute;
	left: -28000px;
	top: -28000px;
	display: none;

	max-width: 300px;
	min-width: 50px;

	direction: ltr;

	box-shadow: none;
	padding: 0;
}

	.qtip-content{
		position: relative;
		padding: 10px;
		overflow: hidden;

		text-align: left;
		word-wrap: break-word;
	}

	.qtip-titlebar{
		position: relative;
		padding: 5px 35px 5px 10px;
		overflow: hidden;

		border-width: 0 0 1px;
		font-weight: bold;
	}

	.qtip-titlebar + .qtip-content{ border-top-width: 0 !important; }

	/* Default close button class */
	.qtip-close{
		position: absolute;
		right: -9px; top: -9px;
		z-index: 11; /* Overlap .qtip-tip */
		cursor: pointer;
		border: 1px solid transparent;
	}

		.qtip-titlebar .qtip-close{
			right: 4px; top: 50%;
			margin-top: -9px;
		}

		* html .qtip-titlebar .qtip-close{ top: 16px; } /* IE fix */

		.qtip-titlebar .ui-icon,
		.qtip-icon .ui-icon{
			display: block;
			text-indent: -1000em;
			direction: ltr;
		}

		.qtip-icon, .qtip-icon .ui-icon{
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			text-decoration: none;
		}

			.qtip-icon .ui-icon{
				width: 18px;
				height: 14px;

				line-height: 14px;
				text-align: center;
				text-indent: 0;
				font: normal bold 10px/13px Tahoma,sans-serif;

				color: inherit;
				background: transparent none no-repeat -100em -100em;
			}

/* Applied to 'focused' tooltips e.g. most recently displayed/interacted with */
.qtip-focus{}

/* Applied on hover of tooltips i.e. added/removed on mouseenter/mouseleave respectively */
.qtip-hover{}

/* Default tooltip style */
.qtip-default{
	border: 1px solid $gray;

	background-color: $lightgray;
	color: #000;
}

	.qtip-default .qtip-titlebar{
		background-color: $gray;
	}

	.qtip-default .qtip-icon{
		border-color: $gray;
		background: $lightgray;
		color: #000;
	}

	.qtip-default .qtip-titlebar .qtip-close{
		border-color: $gray;
		color: #000;
	}


.qtip .qtip-tip{
	margin: 0 auto;
	overflow: hidden;
	z-index: 10;

}

	/* Opera bug #357 - Incorrect tip position
	https://github.com/Craga89/qTip2/issues/367 */
	x:-o-prefocus, .qtip .qtip-tip{
		visibility: hidden;
	}

	.qtip .qtip-tip,
	.qtip .qtip-tip .qtip-vml,
	.qtip .qtip-tip canvas{
		position: absolute;

		color: #123456;
		background: transparent;
		border: 0 dashed transparent;
	}

	.qtip .qtip-tip canvas{ top: 0; left: 0; }

	.qtip .qtip-tip .qtip-vml{
		behavior: url(#default#VML);
		display: inline-block;
		visibility: visible;
	}
/* ==========================================================================
	Footer
========================================================================== */

#footer{
	color: #FFF;
	background:#767676;

	.wrapper{
		max-width:768px;
		margin: 0 auto;
		padding:20px;
		text-align:center;
	}

	ul{
		margin: 0 auto 0.75em;
		padding:0;
		list-style-type: none;
	}

	li{
		display: inline-block;
		vertical-align: top;
		margin-right:25px;
	}

	a{
		color:#fff;
		@include adjust-font-size-to(12px);

		&:hover{
			color:$blue;
		}
	}

	p{
		position: relative;
		margin: 0;
		@include adjust-font-size-to(11px);
	}

	span{
		position: relative;
		padding:3px;
		top: -3px;
		font-weight:700;
	}
}